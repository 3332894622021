import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const ContactPage = () => {
  return (
    <Layout>
        <Seo 
          title="Contact Us"
          description="We would love to hear from you, ways and means to get in touch with us"
        />
        <div className="mx-4 lg:max-w-screen-lg lg:mx-auto"> 
          <h1 className="text-5xl font-bold text-anomaly-red">Contact Us</h1>
        </div>

        <p className="mx-4 my-8 text-3xl text-gray-500 lg:text-4xl lg:my-24 lg:mx-auto lg:text-center">
          Every project starts with a conversation. Let's begin.
        </p>

        <div className="grid max-w-screen-md grid-cols-1 grid-rows-2 gap-8 mx-4 md:mx-auto md:grid-cols-2">
          <address className="text-2xl not-italic text-gray-500">
            <h2 className="mb-1 text-3xl font-semibold text-anomaly-red">Canberra</h2>
            <p className="mb-4 text-3xl font-semibold">Ngunnawal Country</p>
            <p><b>The Hub, Civic Quarter</b></p>
            <p>Level 1, 68 Northbourne Avenue</p>
            <p>Canberra (ACT) 2601</p>
            <p>Australia</p>
            <a className="flex items-center mt-8 text-lg text-anomaly-red" href="https://goo.gl/maps/NSvAYgvabTbirBEUA">
              See on a map
              <svg className="w-4 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </a>
          </address>
          <address className="text-2xl not-italic text-gray-500">
            <h2 className="mb-1 text-3xl font-semibold text-anomaly-red">Wagga Wagga</h2>
            <p className="mb-4 text-3xl font-semibold">Wiradjuri Country</p>
            <p><b>B474 AgriPark</b></p>
            <p>Tooma Way</p>
            <p>Wagga Wagga (NSW) 2650</p>
            <p>Australia</p>
            <a className="flex items-center mt-8 text-lg text-anomaly-red" href="https://maps.google.com/?q=-35.0605233,147.3541824">
              See on a map
              <svg className="w-4 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </a>
          </address>
          <address className="text-2xl not-italic text-gray-500">
            <h2 className="mb-1 text-3xl font-semibold text-anomaly-red">Adelaide</h2>
            <p className="mb-4 text-3xl font-semibold">Kaurna Country</p>
            <p>Adelaide (SA) 5000</p>
            <p>Australia</p>
            {/* <a className="flex items-center mt-8 text-lg text-anomaly-red" href="https://maps.google.com/?q=-35.0605233,147.3541824">
              See on a map
              <svg className="w-4 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </a> */}
          </address>
          <address className="text-2xl not-italic text-gray-500">
          </address>

          <section className="text-2xl text-gray-500 lg:mt-0">
            <h2 className="mb-4 text-3xl font-semibold text-anomaly-red">Speak to us?</h2>
            <p><a href="mailto:hello@anomaly.ltd">hello@anomaly.ltd</a></p>
          </section>
          <section className="text-2xl text-gray-500 lg:mt-0">
            <h2 className="mb-4 text-3xl font-semibold text-anomaly-red">Ready to build?</h2>
            <p><a href="mailto:begin@anomaly.ltd">begin@anomaly.ltd</a></p>
          </section>
        </div>

    </Layout>
 );
};
export default ContactPage
